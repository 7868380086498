import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <iframe src="https://eaitalento.zohorecruit.eu/forms/9587fc4889886ae7a5f31f96d3d24a6f83d0c729b2573c53bd8e9198951dc1db" width="100%" height="2200px" frameborder="0" ></iframe>
        </div>
      </div>
    </section>
  </Layout>
  );
